import React from 'react';
import {Form,Input,Button,message, Checkbox,Spin} from 'antd';
import axios from 'axios';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import * as serverconfig from '../../views/serverconn'

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''


class  ClientCo_UserCreationForm extends React.Component {

  state = {
    isactive:false,
    ismanager:false,
    profilepic:null,  
    datarequested:false,  
 
  }

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }

  formRef = React.createRef();

    // on form submit heree
    onFinish = values => {
      this.setState({datarequested:true})

      //create form data for updating staff status
      let form_data = new FormData();
      form_data.append('username', values.username);
      form_data.append('email', values.email,);
      form_data.append('password1', values.password);
      form_data.append('password2', values.confirm);
      form_data.append('sacco', this.props.saccoId);
      form_data.append('is_active', this.state.isactive);
      form_data.append('branch_manager', this.state.ismanager);

      this.state.profilepic==null?
      console.log("No profile file")
      :
      form_data.append('profile_pic', this.state.profilepic, this.state.profilepic?this.state.profilepic.name:"");

      //Register the account first
          axios.post(serverconfig.backendserverurl+'/customqueries/createcompanyuser',form_data).
          then(res =>{
            console.log(res.data)
            this.setState({datarequested:false})
            window.location.reload(false)
            message.info("successfully created user")
          })
        .catch(error => console.log(error))

    };

    onActiveCheckboxChange = e => {
      this.setState({isactive:e.target.checked});
    };

    onManagerCheckboxChange = e => {
      this.setState({ismanager:e.target.checked});
    };

    handleprofilepicChange= (e) => this.setState({ profilepic: e.target.files[0]});


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <Form
          ref={this.formRef}
          name="user reg form"
          onFinish={this.onFinish}
        >

        <Form.Item
            label="User Name"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username"/>
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />

          </Form.Item>
    
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password 
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            />


          </Form.Item>

    
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
    
                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}
          >
            <Input.Password 
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password" />

          </Form.Item>

          <Form.Item
            name="active"
            label="Active"
            rules={[
              {
                required: false,
                message: '',
              },
            ]}
          >
            <Checkbox onChange={this.onActiveCheckboxChange}></Checkbox>
            </Form.Item>

            <Form.Item
            name="ismanager"
            label="Is Manager"
            rules={[
              {
                required: false,
                message: '',
              },
            ]}
          >
            <Checkbox onChange={this.onManagerCheckboxChange}></Checkbox>
            </Form.Item>

          <Form.Item label="Profile Image"
            name="profilepic">
              <Input  type="file" accept="image/png, image/jpeg" placeholder="Profile Image" onChange={this.handleprofilepicChange}/>
          </Form.Item>  

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create User
            </Button>
            </Form.Item>
          
        </Form>
      );

    }

   
  }

}


export default ClientCo_UserCreationForm;


