import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button,Spin,Card,Avatar,Anchor,BackTop,Affix,Image } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined, PhoneFilled, PhoneOutlined, MessageOutlined, MailOutlined, BankOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'
import * as actions from '../../store/actions/auth'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { Layout, Menu, Breadcrumb,Carousel,Tabs,Collapse } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import logo from '../../assets/Pitech-logoconv.png';

const { Header, Content, Footer } = Layout;

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Meta } = Card;

const contentStyle = {
  //height: '200px',
  color: '#fff',
  lineHeight: '200px',
  //textAlign: 'center',
  background: '#BEBBB9',
  padding:5,
  //borderRadius:30
};

const style = {
  height: 40,
  width: 40,
  lineHeight: '40px',
  borderRadius: 4,
  backgroundColor: '#2F54EB',
  color: '#fff',
  textAlign: 'center',
  fontSize: 14,
};

class NormalLoginForm extends React.Component {

  state = {
    userName:'' ,  
    password:'',
    companyprofile:{},
    top: 2,
    datarequested:true,

  }

  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    this.setState({datarequested:false})
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.userName !='' || this.state.password !='' ){
        this.props.onAuth(this.state.userName,this.state.password)
    }
  }
  
  //Methods to get username and password
  handleUserNameChange= (e) => this.setState({ userName: e.target.value});
  handlePasswordChange= (e) => this.setState({ password: e.target.value});



  render(){
    let errorMessage=null;
    if (this.props.error){
      errorMessage = (
        <p style={{color:'red'}}>User Name or Password is wrong</p>
      );
    }


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          {
            this.props.loading ?
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
            <Spin indicator={antIcon} />
            </div>
            :
            <div>
               
                  <Card style={{display:'flex',width:'100%',alignSelf:'center',alignItems:'center',justifyContent:'center'}}>
                    {errorMessage}

                      <div style={{display: 'flex',  justifyContent:'center', alignSelf:'center' }}>
                      <Avatar
                      size={60}
                      icon={<UserOutlined />}
                      alt="Logo"
                      src={logo}
                      />
                      </div>
                      <h3 style={{color:'#2F54EB',display: 'flex',  justifyContent:'center', alignSelf:'center' }}>GateWay Admin Login</h3>
                      <Form
                      name="normal_login"
                      className="login-form">
  
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Username!',
                          },
                        ]}
                      >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" value={this.state.userName} onChange={this.handleUserNameChange} />
                      </Form.Item>
  
  
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Password!',
                          },
                        ]}
                      >
                        <Input
                          prefix={<LockOutlined className="site-form-item-icon" />}
                          type="password"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.handlePasswordChange}
                        />
                      </Form.Item>
  
                      <Form.Item>
                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                        <Button shape="round" onClick={this.handleSubmit} type="primary" htmlType="submit" className="login-form-button">
                          Log in
                        </Button>
                        </div>
                      </Form.Item>
                    </Form>    
                  </Card>
  
                                  
        </div>
             
                
          }
  
     </div>
  
        );

    }

  }
}

const WrappedNormalLoginForm= NormalLoginForm;

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }

}

export default withRouter (connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm));
